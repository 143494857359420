import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import video_1 from './videos/01.mp4';
import video_2 from './videos/02.mp4';
import video_3 from './videos/03.mp4';
import CoverSelector from './components/cover-selector';

function VideoPlayer() {
    const [showCover, setShowCover] = useState(true);
    const [videoIndex, setVideoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playCount, setPlayCount] = useState(0);

    const videos = [
        { url: video_1 },
        { url: video_2 },
        { url: video_3 }
    ];

    const handleVideoEnded = () => {
        setShowCover(true);
        setIsPlaying(false);
        setPlayCount(playCount + 1);
    };

    const handleCoverClick = (direction) => {
        if (direction === 'left') {
            setVideoIndex((videoIndex - 1 + videos.length) % videos.length);
        } else if (direction === 'right') {
            setVideoIndex((videoIndex + 1) % videos.length);
        } else {
            setShowCover(false);
            setIsPlaying(true);
        }
    };

    return (
        <div style={{ backgroundColor: 'black', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {showCover ? (
                <div>
                    <CoverSelector handleCoverClick={handleCoverClick} playCount={playCount} />
                    <ReactPlayer key={videos[videoIndex].url} url={videos[videoIndex].url} controls={false} playing={isPlaying} onEnded={handleVideoEnded} width="100vw" height="100vh" />
                </div>
            ) : (
                <ReactPlayer key={videos[videoIndex].url} url={videos[videoIndex].url} controls={false} playing={isPlaying} onEnded={handleVideoEnded} width="100vw" height="100vh" />
            )}
        </div>
    );
}

export default VideoPlayer;
