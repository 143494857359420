import React from 'react';

const CoverSelector = ({ handleCoverClick, playCount }) => {
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 flex justify-center items-center gap-8">
            {playCount < 2 ? (
                <>
                    <button onClick={() => handleCoverClick('left')} className="cover-button ml-10 text-white text-lg px-4 py-2 transition duration-300 shadow-sexy-pink hover:scale-110 hover:bg-opacity-80 cursor-pointer">
                        看她穿黑丝
                    </button>
                    <button onClick={() => handleCoverClick('middle')} className="cover-button text-white text-lg px-4 py-2 transition duration-300 shadow-sexy-pink hover:scale-110 hover:bg-opacity-80 cursor-pointer">
                        观看此视频
                    </button>
                    <button onClick={() => handleCoverClick('right')} className="cover-button mr-10 text-white text-lg px-4 py-2 transition duration-300 shadow-sexy-pink hover:scale-110 hover:bg-opacity-80 cursor-pointer">
                        看她魅魔形态
                    </button>
                </>
            ) : (
                <div className="text-white text-lg text-center">
                    <p>更多形态，请前往随信APP观看...</p>
                    <a href="https://apps.apple.com/cn/app/dodopod/id6475193964" className="underline cursor-pointer">点击跳转</a>
                </div>
            )}
        </div>
    );
}

export default CoverSelector;
