import React, { useEffect } from 'react';
import './App.css';
import VideoPlayer from './videoplayer';

function App() {
  useEffect(() => {
    document.title = "完蛋！！抖音美女包围我...";
  }, []);

  return (
    <div className="App flex justify-center w-full h-full overflow-hidden">     
        <VideoPlayer />
    </div>
  );
}

export default App;
